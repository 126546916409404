/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
::-webkit-scrollbar {
  scrollbar-width: thin !important;
  scrollbar-color: #000000 #f5f5f5 !important; /* Change the first color to black */
}

::-webkit-scrollbar-thumb {
  width: 1px !important;
}
