@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cookie&family=Dancing+Script:wght@700&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css");

.home-image {
  background-color: #2d324d;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(./images/login-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100% !important;
  overflow: hidden;
  /* min-height: 44vh; */
  background-position: center right;
}

.home-bg-image {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.background-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2d324d;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(./images/back.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center right;
  animation: zoom-in-zoom-out 15s ease infinite;
  z-index: 0;
}

.hero_head {
  position: absolute;
  cursor: pointer;
  width: 20%;
  transform: translate(-50%, -50%);
  /* color: white; */
}
@media screen and (max-width:768px) {
  .hero_head {
    width: 30%;
  }
}
@media screen and (max-width:576px) {
  .hero_head {
    width: 50%;
  }

}
.image-container {
  z-index: 1;
}

.card-image {
  max-width: 100%;
  height: auto;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
  }
}

.h1 {
  font-family: "Cookie", cursive;
  font-size: 4.5rem !important;
}

.cursor-pontor {
  cursor: pointer;
}

body {
  background-image: url(./images/bg-image-2.png);
  background-repeat: no-repeat;
    background-position: center;
  /* overflow: hidden; */
  background-attachment: fixed;
  background-color: #e5e5e5 !important;
}

/* -----------spinner---------------  */

.spinner-border {
  --bs-spinner-width: 1rem !important;
  --bs-spinner-height: 1rem !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  /* Adjust based on your layout */
  /* Add any other styles you need */
  background-color: #e5e5e5 !important;
}

.bg-image {
  background-image: url(./images/login-bg.png);
  background-size: cover;
}

.login-btn {
  width: 356px;
  height: 48px;
  border-radius: 25px;
  background-color: #000000;
  border: 1px solid #4d4c4c57;
  box-shadow: 9px 11px 11px rgba(0, 0, 0, 0.3);
}

.login {
  height: 100vh;
  text-align: center;
  position: relative;
}

.calander-btn {
  position: fixed;
  bottom: 0;
  margin-bottom: 100px;
}

h1 {
  /* font-family: 'Dancing Script', cursive; */
  /* font-family: 'Cookie', cursive;

font-family: 'Dancing Script', cursive; */
  font-family: "Cookie", cursive;
  font-size: calc(1.375rem + 1.5vw) !important;
}

.app-wrapper {
  padding-left: 212px !important;
}

.app-sidepanel {
  position: relative;
  z-index: 10;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 8px 15px;
  width: 90px !important;
  height: 100%;
}

.app-sidepanel {
  background-color: transparent !important;
}

.label {
  font-size: 17px !important;
  color: #000000 !important;
  font-weight: 367 !important;
  margin-top: 8px;
}

.inpt {
  width: 356px !important;
  height: 48px !important;
  border-radius: 23px !important;
  background-color: #00000000 !important;
  border: 1px solid #000000 !important;
  /* background-color: transparent !important; */
} 
.inpt-number {
  width: 229px !important;
  height: 48px !important;
  border-radius: 23px !important;
  background-color: #00000000 !important;
  border: 1px solid #000000 !important;
  /* background-color: transparent !important; */
}

.login-input {
  width: 356px !important;
  height: 48px !important;
  border-radius: 23px !important;
  /* background-color: #000000 !important; */
  border: 1px solid #000000 !important;
  /* background-color: transparent !important; */
}

.PhoneInputCountry {
  width: 94px !important;
  height: 39px !important;
  border-radius: 22px !important;
  background-color: #4d4d4d !important;
  height: 48px !important;
}

.arrow {
  left: 28px !important;
  /* background-color: #fdfdfd !important; */
  border-top: 4px solid #fff !important;
}

.btn-g {
  width: 150px !important;
  height: 38px !important;
  border-radius: 19px !important;
  background-color: #666666 !important;
  font-size: 13px !important;
  color: #ffffff !important;
  font-weight: 700 !important;
  /* font-family: "Montserrat" !important; */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
}

.btn-b {
  width: 150px !important;
  height: 38px !important;
  border-radius: 19px !important;
  background-color: #000000 !important;
  font-size: 13px !important;
  color: #ffffff !important;
  font-weight: 700 !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
}

.PhoneInputCountryIcon {
  text-align: center !important;
  margin-left: 22px !important;
  font-size: 20px !important;
  color: #fdfdfd !important;
}

.PhoneInputInput {
  width: 248px !important;
  height: 48px !important;
  border-radius: 23px !important;
  /* background-color: #000000 !important; */
  border: 1px solid #000000 !important;
  padding-left: 10px;
  background-color: transparent;
}

.PhoneInputInput {
  flex: 1 1;
  max-width: 254px !important;
}

/* -----------sidebar ---------------------------- */

.sidebar {
  width: 212px;
  height: 634px;
  border-radius: 33px;
  background-color: #000000;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  box-shadow: 14px 0px 14px 0px grey;
  display: block;
}

.f-12 {
  font-size: 18px;
  color: #ffffff;
  font-family: "Montserrat";
}

.list-style {
  list-style-type: none;
}

.icon {
  text-align: end !important;
}

.profile-image {
  border: 1px solid #000000 !important;
  padding: 12px;
  width: 40%;
  border-radius: 4%;
}

::-webkit-input-placeholder {
  color: transparent;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: transparent;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent;
}

:-ms-input-placeholder {
  color: transparent;
}

.inpt-border-trans {
  border-color: transparent !important;
  background-color: white !important;
}

body {
  font-family: sans-serif !important;
  font-weight: 300;
}

#content {
  margin-left: 250px;
  padding: 15px;
}

#sidebar a :hover {
  color: #ffffff;
  font-weight: 500;
}

.sidebar-a {
  padding: 8px 15px;
  text-decoration: none;
  font-size: 18px;
  color: #ffffff;
  display: block;
  cursor: pointer;
}

i {
  margin-right: 10px;
}

.sidebar-a:hover {
  color: #d8dde2;
}

.sidebar-img {
  width: 44%;
  border-radius: 50%;
}

p {
  color: white;
}

#searchInput {
  width: 80%;
  padding: 8px;
  background-color: #000000;
  border: 1px solid #ffffff;
  border-radius: 5px;
  color: #ffffff;
}

.price {
  border-radius: 29px;

  border: 1px solid #000000;
  width: 356px !important;
}

.price-jod {
  border-radius: 24px;

  border: 1px solid #000000;
  width: 229px !important;
}

.price-payment {
  border-radius: 24px;

  border: 1px solid #000000;
  width: 100% !important;
}

.inpt-lable {
  /* width: 53px;
height: 39px; */
  /* border-radius: 19px; */
  background-color: #4d4d4d !important;
  border-radius: 24px !important;
  color: #fff !important;
  padding: 10px 19px !important;
  min-width: 76px !important;
}

.payment-box {
  opacity: 0.702;

  background-color: #cbcbcb;
  width: 60%;
}

.form-check-input:checked {
  background-color: #080909 !important;
  border-color: #060606 !important;
}

.centerText {
  text-align: -webkit-center !important;
}

/* -----------------------sidebar---------------------------------- */

#sidebar {
  max-height: 100%;
  height: 80%;
  width: 282px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000;
  padding: 20px;
  color: white;
  overflow-x: hidden;
  border-radius: 0px 35px 35px 0px;
  box-shadow: 9px 3px 8px 0px rgb(0 0 0 / 34%);
  transition: all 0.3s;
  overflow-y: hidden;
  margin-top: 97px;
  z-index: 1000 !important;
  display: block;
}

#sidebar1 {
  max-height: 100%;
  height: 100%;
  width: 300px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000;
  padding: 20px;
  color: white;
  overflow-x: hidden;
  border-radius: 0px;
  box-shadow: 9px 3px 8px 0px rgb(0 0 0 / 34%);
  transition: all 0.3s;
  overflow-y: auto;
  margin-top: 0;
  z-index: 1000 !important;
  display: none;
}

/* #manubar {
  height: 50%;
  overflow-y: scroll;
  scrollbar-width: thin;
}

#manubar1 {
 
  scrollbar-width: thin;
}

#manubar::-webkit-scrollbar {
  width: 1px;
}

#manubar::-webkit-scrollbar-thumb {
  background-color: #666;
  border-radius: 3px;
}

#manubar::-webkit-scrollbar-track {
  background-color: #161515;
} */

#manubar {
  height: 50%;
  overflow-y: scroll;
  scrollbar-width: auto; /* For Firefox */
}

#manubar::-webkit-scrollbar { /* Start of section for Chrome, Safari, and Opera */
  width: 1px;
}

#manubar::-webkit-scrollbar-thumb {
  background-color: #666;
  border-radius: 3px;
}

#manubar::-webkit-scrollbar-track {
  background-color: #161515;
} /* End of section for Chrome, Safari, and Opera */

#manubar1 {
  /* height: 70%; */
  /* overflow-y: scroll; */
  scrollbar-width: auto; /* For Firefox */
}

#manubar1::-webkit-scrollbar { /* Start of section for Chrome, Safari, and Opera */
  width: 1px;
}

#manubar1::-webkit-scrollbar-thumb {
  background-color: #666;
  border-radius: 3px;
}

#manubar1::-webkit-scrollbar-track {
  background-color: #161515;
} /* End of section for Chrome, Safari, and Opera */


#close-icon {
  display: none;
}

#sidebar a {
  margin-top: 8px;
  padding: 8px 15px;
  text-decoration: none;
  font-size: 18px;
  color: #ffffff;
  display: block;
  font-weight: 300;
  cursor: pointer;
}

#sidebar1 a {
  margin-top: 8px;
  padding: 8px 15px;
  text-decoration: none;
  font-size: 18px;
  color: #ffffff;
  display: block;
  cursor: pointer;
}

.sidebar-a {
  margin-top: 8px;
  padding: 8px 15px;
  text-decoration: none;
  font-size: 18px;
  color: #ffffff;
  display: block;
}

i {
  margin-right: 10px;
}

/* img {
       width: 40%;
      border-radius: 50%;
   } */

#searchInput {
  margin-bottom: 10px;
  width: 88%;
  background-color: #000000;
  border: 1px solid #ffffff;
  border-radius: 5px;
  color: #ffffff;
  background-image: url(./images/search-icon.png);
  background-position: 10px 5px;
  background-repeat: no-repeat;
  padding: 9px 12px 9px 36px;
  font-size: 12px;
}

.sidebar-open {
  margin-left: 0;
}

#manu-icon {
  display: none;
}

/* -------------add user role--------- */

.card {
  width: 26%;
  border-color: #000000 !important;
  background: #e4e4e4 !important;
}

.all_border {
  border-bottom: #000000;
  background-color: #666666;
}

.header_color {
  background-color: #66666661 !important;
}

.form-check .form-check-input[type="checkbox"] {
  float: left;
  margin-left: -1.5em;
  width: 28px;
  height: 28px;
  border-radius: 0%;
  margin-left: 15px;
  border-color: #000000;
  --bs-form-check-bg: transparent;
}

.form-check .form-check-input[type="radio"] {
  float: left;
  margin-left: -1.5em;
  width: 35px;
  height: 35px;
  border-radius: 50% !important;
  margin-left: 10px;
  border-color: #000000;
  background-color: transparent !important;
}

.form-check-input:checked[type="radio"] {
  background-color: black !important;
}

.form_check_size {
  text-align: center;
  padding-left: 10px;
  padding-top: 5px;
}

.cash_lable {
  padding-left: 20px;
  padding-top: 8px;
}

.inpt-border-trans {
  border: 1px solid #000000 !important;
  border-color: transparent !important;
  width: 24% !important;
  background-color: transparent !important;
}

.inpt-border-trans-number {
  border: 1px solid #000000 !important;
  border-color: transparent !important;
  width: auto !important;
  background-color: transparent !important;
}

.inpt-border-trans-pass {
  border: 1px solid #000000 !important;
  border-color: transparent !important;
  width: 18% !important;
  background-color: transparent !important;
}

/*
.price {
  border-radius: 24px;

  border: 1px solid #000000;
  width: auto !important;
} */

.inpt-lable {
  border-radius: 19px;
  background-color: #4d4d4d !important;
  border-radius: 28px !important;
  color: #fff !important;
  padding: 10px 19px !important;
}

.inpt-lable-light {
  /* width: 53px;
height: 39px; */
  /* border-radius: 19px; */
  background-color: #a5a5a5fc !important;
  border-radius: 28px !important;
  color: #fff !important;
  padding: 10px 19px !important;
  min-width: 76px !important;
}

/* .payment-box {
  opacity: 0.702;

  background-color: #cbcbcb;
} */

.text_area_size {
  width: 30% !important;
}

.jod_width_cal {
  width: 200px !important;
}

.react-datepicker__day--selected {
  background-color: pink !important;
  color: #7a1f1f !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: pink !important;
  color: #7a1f1f !important;
}

.react-datepicker-popper {
  margin-left: -211px !important;
  z-index: 4 !important;
}

.cal_width {
  height: 77% !important;
}

.input_filed {
  display: flex;
}

/* option:checked {
  background-color: #ccc !important;
}

option:hover {
  background-color: #15a215 !important;
}

option {
  font-weight: normal;
  display: block;
  white-space: pre;
  min-height: 1.2em;
  padding: 0px 2px 1px;
  border-radius: 10% !important;
  background-color: transparent !important;
} */

.payment-lable {
  font-size: small;
  font-weight: 300;
}

.check-label {
  margin-top: 11px !important;
  margin-left: 6px;
  font-size: 14px;
}
.kitchen-calender div {
  border-radius: 8px !important;
  padding: 0px !important;
}

.react-datepicker-wrapper {
  border-radius: 23px 23px !important;
  background-color: #4d4d4d;
  padding: 4px 11px !important;
}

.react-datepicker__input-container svg {
  font-size: 20px;
  color: white;
}

.menu-price {
  width: 59% !important;
}

.large-black-btn {
  width: 170px;
  height: 60px;
  border-radius: 10px !important;
  background-color: #000000 !important;
  color: white !important;
}

.large-gray-btn {
  width: 170px;
  height: 60px;
  border-radius: 10px !important;
  background-color: #b3b3b3 !important;
  color: white !important;
}

.food-btn {
  position: fixed;
  bottom: 0;
  margin-bottom: 22px;
}

.line_sort {
  display: none;
}

/* .svg-container {
  position: absolute;
  top: 0;
  left: 2%;

  height: 49%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
} */

.svg-container {
  position: absolute;
  top: 100%;
  left: 2%;
  height: 24%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.time-inpt {
  /* width: 181px !important; */
  width: 70% !important;
  height: 48px !important;
  border-radius: 23px !important;
  border: 1px solid #000000 !important;
  background-color: transparent !important;
}

.time-inpt2 {
  /* width: 181px !important; */
  width: 90% !important;
  height: 48px !important;
  border-radius: 23px !important;
  border: 1px solid #000000 !important;
  background-color: transparent !important;
}

.sub-sidebar {
  margin-top: 8px;
  padding: 8px 15px;

  text-decoration: none;
  font-size: 16px !important;
  color: #ffffff;
  display: block;
}

.active-sub-link {
  /* border-bottom: 2px solid rgb(255, 255, 255) !important;
  margin-bottom: 5px !important; */
  position: relative;
}

/* Define styles for the active link */

.active-sub-link::after {
  content: "";
  position: absolute;
  right: 20px;
  bottom: -3px;
  /* Adjust this value to set the space between text and underline */
  width: 65%;
  /* Adjust the width of the underline */
  border-bottom: 2px solid rgb(255, 255, 255);
  border-right: 0 !important;
}

/* Define styles for the font color of the active link */
.active-font {
  font-weight: 500 !important;
  color: #ffffff !important;
}

.margin-200 {
  margin-top: 200px;
}

.on-off {
  width: 4em !important;
  height: 2em !important;

  margin-left: 0 !important;
  border-radius: 2em !important;
  border: 3px solid #4d4d4d !important;
}
.on-off2 {
  width: 2.5em !important;
  height: 1.2em !important;

  margin-left: 0 !important;
  border-radius: 2em !important;
  border: 3px solid #4d4d4d !important;
}
.inpt-border-setting {
  border: 1px solid #000000 !important;
  border-color: transparent !important;
  width: 22% !important;
  /* background-color: transparent !important; */
}

.inpt-lable2 {
  /* width: 53px;
height: 39px; */
  /* border-radius: 19px; */
  background-color: #4d4d4d !important;
  border-radius: 24px !important;
  color: #fff !important;
  padding: 10px 19px !important;
  min-width: 76px !important;
}

.setting-price {
  border-radius: 29px;

  border: 1px solid #000000;
  width: auto !important;
}

.rbc-selected-cell {
  background-color: rgb(255 0 0 / 10%) !important;
}

.backg-black {
  background-color: #000000;
}

.rbc-btn-group {
  display: none !important;
}

.rbc-calendar {
  border-radius: 30px !important;
  background-color: #f1f1f1 !important;
  border: none;
  box-shadow: 0px 0px 16px rgb(0 0 0 / 72%) !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.rbc-day-bg {
  border: 1px solid black;
  /* border-bottom: none; */
  margin-top: -1px;
}

.rbc-header {
  border-left: none !important;
}

.rbc-month-header {
  border-bottom: 1px solid black;
}

.rbc-button-link {
  pointer-events: none;
}

.rbc-header > span {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-transform: uppercase !important;
}

.rbc-date-cell {
  text-align: center !important;
  margin-top: 11px;
}

/* --------------------------responsive ----------------------------------------- */

#sidebar {
  max-height: 100%;
  height: 80%;
  width: 282px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000;
  padding: 20px;
  color: white;
  overflow-x: hidden;
  border-radius: 0px 35px 35px 0px;
  box-shadow: 9px 3px 8px 0px rgb(0 0 0 / 34%);
  transition: all 0.3s;
  overflow-y: hidden;
  margin-top: 97px;
  z-index: 1000 !important;
  display: block;
}

.form-check-input:focus {
  /* border-color: #86b7fe;
  outline: 0; */
  box-shadow: none !important;
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e") !important;
}

.form-switch .form-check-input:checked {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
}

.fs-16 {
  font-size: 16px !important;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none !important;
}

.otp-field div {
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 4px;
}

.otp-field div input {
  width: 1em;
  text-align: center;
  border-top: 0;
  border-right: 0;
  border-left: 0;
}

.otp-field div span {
  color: white;
}

.otp-field {
  height: 48px !important;
  border-radius: 23px !important;
  /* background-color: #000000 !important; */
  border: 1px solid #000000 !important;
}

.blue-color {
  color: rgb(75, 75, 238);
}

.react-tel-input .form-control {
  background: transparent !important;
  border: 0 !important;
  position: relative;
  font-size: 17px !important;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 88px !important;
  padding-top: 17px;
}

.react-tel-input .flag-dropdown {
  padding: 19px !important;
  background-color: #4d4d4d !important;
  border: 0 !important;
  border-radius: 27px !important;
}

.react-tel-input .flag-dropdown.open .selected-flag,
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  box-shadow: none !important;
}

.react-tel-input .country-list .country-name {
  margin-right: 6px;
  color: white !important;
}

.react-tel-input .country-list .country:hover {
  background-color: #676767 !important;
  /* color: black !important; */
}

.react-tel-input .country-list .country.highlight {
  background-color: #676767 !important;
}

.react-tel-input .country-list .country .dial-code {
  color: #9d9b9b !important;
}

.form-select:focus {
  /* border-color: #86b7fe;
  outline: 0; */
  box-shadow: none !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus,
.react-tel-input .flag-dropdown.open .selected-flag {
  background: #4d4d4d !important;
}

/*------------sidebar --------------------*/
  .table-responsive {
    display: block !important;
    -webkit-overflow-scrolling: touch;
  }

.scroll-table {
  max-height: none;
  overflow-y: auto;
}

.scrollbar-thin {
  scrollbar-width: auto;
  /* scrollbar-color: #0bc3f0 #f5f5f5; */
}

.scrollbar-thin::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  /* background-color: #fefefe !important; */
  border-radius: 10px;
}
.react-tel-input .country-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  /* background-color: #fefefe !important; */
  border-radius: 10px;
}
.react-tel-input .country-list::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0) !important;
  border-radius: 10px;
}
.react-tel-input .country-list::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.scroll-table::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.scroll-table::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0) !important;
  border-radius: 10px;
}

.app-sidepanel .app-nav-main::-webkit-scrollbar {
  background-color: transparent !important;
}

.tableScroll {
  overflow-x: scroll;
  max-width: 95vw;
}

.plus-icon {
  font-size: 47px;
  margin-top: 6px;
}

.css-4jnixx-MuiStack-root {
  padding: 0 !important;
  border: 0 !important;
  border-radius: 22px !important;
  width: 50%;
}

.MuiOutlinedInput-notchedOutline {
  border-width: 0 !important;
  border-color: transparent !important;
}

.MuiClock-pin,
.MuiClockPointer-root {
  background-color: #666666 !important;
}

.MuiClockPointer-thumb {
  background-color: #666666 !important;
  border: 16px solid #666666 !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  color: black !important;
}

.MuiInputBase-input {
  height: 0 !important;
  padding: 23.5px 14px !important;
}

.MuiStack-root {
  display: flex !important;
  padding-top: 0 !important;
  width: 158px !important;
}

.css-13cymwt-control,
.css-t3ipsp-control,
.css-t3ipsp {
  background-color: transparent !important;
  border-color: hsl(0deg 7.24% 56.29% / 0%) !important;
  border-radius: 44px !important;
  border-style: solid;
  border-width: 0;
  height: 49px !important;
  width: 100% !important;
  box-sizing: border-box;
  box-shadow: none !important;
}

.css-1nmdiq5-menu {
  margin: 0 !important;
}

.noti-card {
  width: 100%;
  background: #fefefe87 !important;
  border: 0.4px solid #000000 !important;
  border-top: 0.4px solid #000000;
}

.noti-span {
  background-color: #b2b2b2;
}

.table-bor {
  border-color: #000000 !important;
}

.table-margin {
  margin-top: 250px !important;
}

.thead-color {
  background-color: #cccccc !important;
}

.btn-add-user {
  width: 179px;
  height: 45px;
  border-radius: 19px !important;
  background-color: transparent !important;
  border-color: #000000 !important;
  font-size: 15px !important;
  color: black !important;
  /* font-weight: 100; */
  padding: 11px 12px !important;
  float: right;
}

.sidebar-icon-active {
  font-size: 19px !important;
}

.active {
  border-right: 3px solid #ffffff;
}

.rbc-selected-cell {
  background-color: rgb(255 0 0 / 10%) !important;
}

.rbc-toolbar {
  height: 67px;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  background-color: #000000;
  color: white;
  margin-bottom: 0px !important;
  display: flex !important;
  justify-content: space-around !important;
}
.backg-black {
  background-color: #000000;
}
.rbc-btn-group {
  display: none !important;
}
.rbc-calendar {
  border-radius: 30px !important;
  background-color: #f1f1f1 !important;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 8px 15px;
}

.rbc-month-view {
  padding: 50px;
  /* / border-bottom: 1px solid black !important; / */
  border: none !important;
  padding-top: 0px;
}

.rbc-day-bg {
  border: 1px solid black;
  /* / border-bottom: none; / */
  margin-top: -1px;
}

.rbc-header {
  border-left: none !important;
}

.rbc-month-header {
  border-bottom: 1px solid black;
}

.rbc-button-link {
  pointer-events: none;
}

.rbc-toolbar-label {
  font-size: 22px;
  font-weight: 300;
}

.rbc-header > span {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-transform: uppercase !important;
}

.rbc-date-cell {
  text-align: center !important;
  margin-top: 11px;
}

.btn-calender {
  margin-top: 0 !important;
  font-size: 15px !important;
  padding: 0px !important;
  font-weight: 500 !important;
}

.date-reservation {
  opacity: 0.502;
  padding: 9px 13px !important;
  border-radius: 30px;
  background-color: #d87db5;
  width: 43px !important;
  height: 42px !important;
  margin-top: 1px;
  margin-left: 9px;
}

.drop_up {
  position: absolute !important;
  right: 9%;
  top: 0%;
  cursor: pointer;
  font-size: 14px !important;
}

.drop_down {
  position: absolute !important;
  right: 9%;
  bottom: 0% !important;
  cursor: pointer;
  font-size: 14px !important;
}

.btn_calender {
  position: relative;
  left: 65%;
}

.next_btn {
  position: relative;
  text-align: end !important;
  right: 25%;
}

.submit_btn {
  position: relative;
  /* / left: 15%; / */
  text-align: end !important;
  right: 25%;
}

.notification-img {
  padding: 13px;
}

.notification-text {
  margin: 14px;
}

.up-arrow {
  float: right;
  /* margin-top: 6px; */
}

.btn-group {
  margin-top: -3px;
}

/* .up-down{
  float: right;
    margin-top: 6px;
} */
.bg-transparant {
  background-color: transparent !important;
}

.li-border {
  border-bottom: 1px solid black !important;
  border-radius: 8px;
  /* margin: 2px 0; */
}

.rbc-month-row {
  cursor: pointer;
}

/*----------------loader-----------------*/
.loader {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #030000;
  top: 47%;
  left: 48%;
  position: absolute;
}

.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #2222228f;
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 1.6s linear infinite;
  /* animation: preloader-wave 1.6s linear infinite; */
}

.loader::after {
  animation-delay: -0.8s;
}
.mini-loader {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #030000;
  top: 21%;
  left: 44%;
  position: absolute;
}

.mini-loader::after,
.mini-loader::before {
  content: "";
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #2222228f;
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 1.6s linear infinite;
  /* animation: preloader-wave 1.6s linear infinite; */
}

.mini-loader::after {
  animation-delay: -0.8s;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.display-for-768 {
  display: none;
}

.display-above-768 {
  display: block;
}

.disply-manu-768 {
  display: block;
}

.table > tbody {
  vertical-align: middle !important;
}
.table-body tr td {
  background-color: #f2f2f2 !important;
}

.dataTables_filter input[type="search"] {
  padding: 5px 10px;
}

.dataTables_filter input[type="search"]::placeholder {
  color: #999;
  font-size: 14px;
}

.dataTables_filter input[type="search"]::-webkit-search-icon {
  background-color: transparent !important;
  color: #000000;
}

.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-radius: 29px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
  margin: 5% auto;
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  /* Adjust this value to set the modal width */
}

.btn-close:focus {
  box-shadow: none !important;
}

.model-card {
  width: 356px !important;
}

/* .inpt-model{
  width: 100% !important;
} */

.modal-footer {
  border-top: none !important;
}

.modal-header-role {
  border-bottom: none !important;
  height: 70px;
  /* background-color: #cccccc !important; */
  align-items: normal !important;
  /* justify-content: center !important; */
}

.modal-header-role h4 {
  align-items: center;
  display: flex;
  text-align: center !important;
  justify-content: center;
  width: 100%;
}

.modal-content {
  padding: 0px;
}

.modal-padd {
  padding-left: 80px !important;
  /* padding-right: 100px !important; */
}

/* ---------------------------responsive---------------------------------------------------------------------- */

/* CSS for screens below 768 pixels */

@media only screen and (max-width: 1440px) {
  .btn_calender {
    position: relative;
    left: 0%;
  }
}

@media only screen and (max-width: 1200px) {
  .modal-padd {
    padding-left: 50px !important;
    padding-right: 30px !important;
  }
}

@media screen and (max-width: 768px) {
  /* Move search input above the "Show entries" dropdown */
  .dataTables_length {
    float: right !important;
    margin-bottom: -205px !important;
    margin-top: 120px;
    text-align: center !important;
  }

  .dataTables_filter label {
    /* float: left !important; */
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .dataTables_filter label input {
    /* float: left !important; */
    width: 100% !important;
  }

  .dataTables_filter input[type="search"]::placeholder {
    font-size: 16px;
    /* Adjust the placeholder font size */
  }

  .dataTables_filter input[type="search"] {
    padding-right: 30px;
    /* Adjust the space for the icon */
  }

  .modal-padd {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media only screen and (max-width: 768px) {
  .table-margin {
    margin-top: 45px !important;
  }
}

@media only screen and (max-width: 768px) {
  .rbc-month-view {
    padding: 10px !important;
    border: none !important;
  }

  .rbc-date-cell {
    padding-top: 7px;
  }
}

@media only screen and (max-height: 1768px) {
  #manubar {
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: auto;
  }
}

@media only screen and (max-height: 1416px) {
  #manubar {
    height: 70%;
    overflow-y: scroll;
    scrollbar-width: auto;
  }
}

@media only screen and (max-height: 1086px) {
  #manubar {
    height: 60%;
    overflow-y: scroll;
    scrollbar-width: auto;
  }
}
@media only screen and (max-height: 805px) {
  #manubar {
    height: 54%;
    overflow-y: scroll;
    scrollbar-width: auto;
  }
}

@media only screen and (max-height: 692px) {
  #manubar {
    height: 40%;
    overflow-y: scroll;
    scrollbar-width: auto;
  }
}

@media only screen and (max-width: 1025px) {
  .payment-box {
    opacity: 0.702;

    background-color: #cbcbcb;
    width: 90%;
  }
}

@media only screen and (max-width: 770px) {
  /* Adjust styles for smaller screens */
  .mt-5 {
    margin-top: 20px;
  }

  #manubar {
    height: 60%;
    overflow-y: scroll;
    scrollbar-width: auto;
  }

  .active-sub-link::after {
    right: 28px;
  }

  #manubar1 {
    /* height: 80%; */
    /* overflow-y: scroll; */
    scrollbar-width: thin;
  }

  .payment-box {
    opacity: 0.702;

    background-color: #cbcbcb;
    width: 100%;
  }

  .padding-left-21 {
    padding: 10rem 0 0 0 !important;
  }

  .header-img {
    width: 27%;
  }

  .icon {
    text-align: end !important;
    display: flex !important;
  }

  .rbc-month-view {
    padding: 10px;
    /* border-bottom: 1px solid black !important; */
    border: none !important;
  }

  .rbc-date-cell {
    margin-top: 19px;
  }
}

@media screen and (max-width: 768px) and (min-width: 576px) {
  /* .padding-left-17 {
    padding-left: 3rem !important;
  } */
  /* .form-margin {
    margin: 0 1rem !important;
  } */
}

@media screen and (max-width: 1200px) and (min-width: 769px) {
  .padding-left-17 {
    padding-left: 19rem !important;
  }
}

@media only screen and (min-width: 1200px) {
  .padding-left-17 {
    padding-left: 19rem !important;
  }

  /* .model-xl{

  } */
  /* .modal-xl-role{
    --bs-modal-width:900px !important;
  } */
}

@media only screen and (max-width: 3603px) {
  .card-login {
    top: 44%;
    left: 17%;
    position: absolute;
  }

  .card-bg {
    left: 47% !important;
    top: 47% !important;
  }

  .login-body {
    position: absolute;
    top: 18%;
    right: 32%;
  }

  .forget-body {
    position: absolute;
    top: 18%;
    right: 32%;
  }

  .p-color {
    color: #000000;
  }

  .login {
    background-color: white;
  }
}

@media only screen and (max-width: 2182px) {
  .card-bg {
    left: 42% !important;
    top: 42% !important;
  }
}

@media only screen and (max-width: 1200px) {
  .card-bg {
    left: 39% !important;
    top: 39% !important;
  }
}

@media only screen and (max-width: 768px) {
  .card-bg {
    left: 33% !important;
    top: 39% !important;
  }
}

@media only screen and (max-width: 678px) {
  .card-bg {
    left: 30% !important;
    top: 39% !important;
  }
  /* .dataTables_length {
    margin-top: 50px;
  } */
}

@media only screen and (max-width: 1441px) {
  .card-login {
    top: 44%;
    left: 15%;
  }

  .login-body {
    position: absolute;
    top: 20%;
    right: 26%;
  }

  .forget-body {
    position: absolute;
    top: 20%;
    right: 26%;
  }

  .p-color {
    color: #000000;
  }
}

@media only screen and (max-width: 1200px) {
  .card-login {
    top: 44%;
    left: 11%;
  }

  .login-body {
    position: absolute;
    top: 22%;
    right: 15%;
  }

  .forget-body {
    position: absolute;
    top: 22%;
    right: 15%;
  }

  .p-color {
    color: #000000;
  }

  .padding-left-17 {
    padding-left: 17rem;
  }
}

@media only screen and (max-width: 991px) {
  .card-login {
    top: 35%;
    left: 0%;
    /* padding-top: 162px; */
    position: inherit;
    width: 26%;
    margin: 139px 6px 38px;
  }

  .login {
    height: auto;
    text-align: center;
    position: relative;
  }

  .login-position {
    position: relative;
    top: 35%;
  }
}

@media only screen and (max-width: 992px) {
  .login-body {
    position: relative;
    top: 0;
    right: 0%;
  }

  .margin-both {
    margin: 0 117px;
  }

  .forget-body {
    position: relative;
    top: 0;
    right: 0%;
  }

  /* .blue-color {
    color: white !important;
    font-weight: bold;
  } */

  /* .p-color {
    color: #ffffff;
  } */
  .login {
    background-color: transparent !important;
  }
}

@media only screen and (max-width: 767px) {
  .margin-both {
    margin: 0 80px;
  }

  .card-login {
    width: 35%;
    margin: 139px 6px 38px;
  }

  .h1 {
    font-family: "Cookie", cursive;
    font-size: 3.5rem !important;
  }
}

@media only screen and (max-width: 576px) {
  .date-reservation {
    opacity: 0.502;
    padding: 9px 13px !important;
    border-radius: 30px;
    background-color: #d87db5;
    width: 43px !important;
    height: 42px !important;
    margin-top: 1px;
    margin-left: 1px;
  }

  .login-body {
    /* position: absolute; */
    /* top: 20%; */
    /* right: 0%; */
    width: 67%;
    margin: 0 auto;
  }

  .margin-both {
    margin: 0;
  }

  .header-img {
    width: 37%;
  }

  .forget-body {
    width: 67%;
    margin: 0 auto;
  }

  /* .p-color {
      color: #ffffff;
    } */
  .calander-btn {
    position: relative !important;
  }

  .food-btn {
    position: relative !important;
  }

  .price {
    border-radius: 24px;
    border: 1px solid #000000;
    width: 100% !important;
  }

  .price-jod {
    border-radius: 24px;
    border: 1px solid #000000;
    width: 100% !important;
  }

  .time-price {
    border-radius: 24px;
    border: 1px solid #000000;
    width: 100% !important;
  }

  .margin-200 {
    margin-top: 50px !important;
  }

  .padding-left-17 {
    padding-left: 0rem !important;
  }

  .drop_up,
  .drop_down {
    right: 7% !important;
  }
}

@media only screen and (max-width: 426px) {
  /* .forget-body {
    position: absolute;
    top: 10%;
    right: 8%;
  } */
}

@media only screen and (max-width: 385px) {
  .forget-body {
    position: inherit;
    top: 7%;
    width: 100%;
    right: 0%;
    margin: 0px 0px;
  }

  .login-body {
    position: inherit;
    top: 7%;
    width: 100%;
    right: 0%;
    margin: 0px 0px;
  }
}

@media screen and (min-width: 1400px) {
  .bottom-section {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .home-image {
    background-color: #2d324d;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(./images/new.png);
    background-repeat: no-repeat;
    background-size: 100% 100% !important;
    overflow: hidden;

    background-position: center right;
  }
}

@media only screen and (max-width: 576px) {
  .login-input {
    width: 100% !important;
    height: 48px !important;
  }

  .inpt {
    width: 100% !important;
    height: 48px !important;
  }

  .login-btn {
    width: 100% !important;
    height: 48px !important;
  }

  .check-phone {
    margin-top: 10px;
    width: 100% !important;
    height: 53px !important;
    border-radius: 23px !important;
    background-color: #00000000 !important;
    border: 1px solid #000000 !important;
  }

  .header {
    margin-left: 0px !important;
  }
}

@media only screen and (min-width: 577px) {
  .time-price {
    border-radius: 24px;
    border: 1px solid #000000;
    width: 328px !important;
    height: 50px;
  }

  .check-phone {
    margin-top: 10px;
    width: 325px !important;
    height: 50px !important;
    border-radius: 23px !important;
    background-color: #00000000 !important;
    border: 1px solid #000000 !important;
  }
}

@media (max-width: 768px) {
  .app-sidepanel {
    width: 60px !important;
  }

  .app-wrapper {
    padding-left: 60px !important;
  }

  .app-nav .nav-link {
    padding: 1.875rem 0.4rem !important;
  }

  .sidebar-icon {
    font-size: 30px !important;
  }
}


@media (min-width: 767px) {
  .price-textarea {
    border-radius: 24px;
    border: 1px solid #000000;
    width: 80% !important;
  }
}

@media (min-width: 1400px) {
  .w-textarea {
    width: 766px !important;
  }
}

@media (max-width: 768px) {
  #sidebar.sidebar-open {
    display: block;
  }

  #sidebar1.sidebar-open {
    display: block;
    left: 0;
  }

  #sidebar {
    display: none;
    position: fixed;
    /* height: 90%; */
  }

  .fixed-top {
    box-shadow: 1px 2px 22px rgb(0 0 0 / 28%);
  }

  #sidebar1 {
    display: block !important;
    position: fixed;
    left: -306px;
    /* height: 90%; */
  }

  .price-textarea {
    border-radius: 24px;
    border: 1px solid #000000;
    width: 100% !important;
  }

  #manu-icon {
    font-size: 30px;
    margin: 20px;
    cursor: pointer;
    display: block;
  }

  #close-icon {
    display: block;
    font-size: 20px;
    color: #ffffff;
    text-align: end;
    margin: 0 0 0 225px;
    cursor: pointer;
  }
}

.fw-3 {
  font-weight: 300 !important;
}

.guest-768 {
  display: block !important;
}

.guest1-768 {
  display: none !important;
}

@media (min-width: 769px) {
  .padding-left-21 {
    padding: 140px 0 0 19rem !important;
  }

  .true-event-type-clnder {
    display: block !important;
    /* padding: 0 25px; */
  }
  .false-event-type-clnder {
    display: block !important;
    /* padding: 0 25px; */
  }
  .true-first-event-type-clnder {
    display: block !important;
    /* padding: 0 25px; */
  }
  .false-first-event-type-clnder {
    display: block !important;
    /* padding: 0 25px; */
  }
  .next-btn-event-true {
    display: block !important;
    /* padding: 0 25px; */
  }

  .next-btn-event-false {
    display: block !important;
    /* padding: 0 25px; */
  }

  .next-btn-event-clnder-true {
    display: block !important;
    /* padding: 0 25px; */
  }

  .next-btn-event-clnder-false {
    display: block !important;
    /* padding: 0 25px; */
  }

  .next-btn-true {
    display: none !important;
    /* padding: 0 25px; */
  }
  .padding-top-768 {
    padding-top: 0;
  }

  .next-btn-false {
    display: none !important;
    /* padding: 0 25px; */
  }
  .next-btn-768 {
    display: block !important;
  }
}

@media screen and (max-width: 1440px) {
  .card {
    width: 32%;
  }
}

@media screen and (max-width: 1040px) {
  .card {
    width: 66%;
  }

  .text_area_size {
    width: 50% !important;
  }
}

@media screen and (max-width: 768px) {
  .input_filed {
    display: block !important;
  }

  .inpt {
    /* width: 330px !important; */
    border: 1px solid #000000 !important;
    /* color: white !important; */
  }

  .all_btn {
    margin-right: 59px;
  }

  .form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 0px !important;
    margin-bottom: 0.125rem;
  }
}

@media screen and (max-width: 520px) {
  .input_filed {
    display: block !important;
  }

  .card,
  .text_area_size {
    width: auto !important;
  }

  .all_btn {
    display: flex;
    position: inherit;
    margin-top: 147px;
  }
}

@media screen and (max-width: 385px) {
  .input_filed {
    display: block !important;
  }

  .inpt {
    width: 100% !important;
  }

  .login-input {
    width: 100% !important;
  }

  .all_btn {
    display: block;
    margin-top: 41px;
  }

  .back_btn {
    margin-bottom: 3px;
  }
  .mini-loader{
    top: 14%;
    left: 39%;
  }
}

@media screen and (max-width: 376px) {
  .react-tel-input .country-list {
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
    background-color: #4d4d4d !important;
    width: 256px !important;
    max-height: 235px;
  }

  .btn_center {
    text-align: center !important;
    width: 100% !important;
  }

  .large-btn-768 {
    display: block !important;
    margin-bottom: 10px;
    width: 100% !important;
  }
}

@media screen and (min-width: 376px) {
  .react-tel-input .country-list {
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
    background-color: #4d4d4d !important;
    width: 300px !important;
    max-height: 235px;
  }
}

@media screen and (max-width: 375px) {
  .card-bg {
    left: 14% !important;
    top: 33% !important;
  }

  .submit_btn {
    right: 4% !important;
  }

  .card-file {
    top: 22% !important;
    right: 7% !important;
  }
  /* .notification-img {
    padding: 14px !important;
    margin: 20px 0px !important;
  } */
}

@media screen and (max-width: 320px) {
  .card-bg {
    left: 8% !important;
    top: 33% !important;
  }

  .submit_btn {
    right: 22% !important;
  }

  .notification-img {
    padding: 11px 7px !important;
    margin: 23px 0px !important;
  }
}

.header-show {
  display: block;
}

.header-show1 {
  display: none;
}

@media (max-width: 769px) {
  .dataTables_wrapper .dataTables_paginate {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 2rem !important;
  }
  #content {
    margin-left: 0;
  }

  .card-file {
    top: 26% !important;
    right: 5% !important;
  }
  #sidebar {
    /* width: 100%; */
    /* height: auto;
    position: absolute; */
    display: none !important;
  }

  .header-show {
    display: none !important;
  }

  .header-show1 {
    display: block !important;
  }

  .line_sort {
    display: block;
    cursor: pointer;
  }

  .add-role-btn-768 {
    display: block !important;
    width: 100% !important;
    text-align: center !important;
    margin: 37px 0px 26px;
  }

  .btn-add-user {
    width: 100% !important;
    float: none !important;
    height: 52px !important;
    border-radius: 29px !important;
    font-size: 19px !important;
  }

  /* .role-table {
    padding-left: 25px;
    padding-right: 25px;
  } */

  .btn-768 {
    display: block !important;
    width: 100% !important;
    height: 52px !important;
    border-radius: 29px !important;
    font-size: 16px !important;
    margin-bottom: 20px !important;
  }

  .h2-768 {
    font-size: 24px !important;
  }

  .form-margin-768 {
    width: 80% !important;
    margin: auto !important;
  }

  .form-margin-add-768 {
    width: 80% !important;
    /* margin: auto !important; */
    margin-left: auto;
    margin-right: auto;
  }

  .form-margin-setting-768 {
    width: 70% !important;
    margin: auto !important;
  }

  .inpt-768 {
    width: 100% !important;
    height: 52px !important;
    border-radius: 33px !important;
  }
  .inpt-number {
    width: 229px!important;
    height: 52px !important;
    border-radius: 33px !important;
  }

  .price-768 {
    width: 100% !important;
    height: 52px !important;
    border-radius: 29px;
  }

  .price-upload-768 {
    /* width: 50% !important; */
    height: 52px !important;
    border-radius: 29px;
  }

  .react-datepicker-wrapper {
    border-radius: 29px 29px !important;
    padding: 4px 19px !important;
  }

  .react-datepicker__input-container svg {
    font-size: 22px;
  }

  .css-1fdsijx-ValueContainer {
    padding: 0px 11px !important;
  }

  .price-jod-768 {
    height: 52px !important;
    border-radius: 29px !important;
  }

  .input-group-text-768 {
    border-radius: 29px !important;
  }

  .display-for-768 {
    display: block;
  }

  .display-above-768 {
    display: none;
  }

  .form-in-768 {
    width: 90%;
    margin: auto;
  }

  .guest-768 {
    display: none !important;
  }

  .guest1-768 {
    display: block !important;
  }

  .large-btn-768 {
    width: 48%;
    height: 70px !important;
    font-size: 18px !important;
    font-weight: 300 !important;
    letter-spacing: 1px;
    margin: 24px 0px;
  }
  .large-btn-768-1 {
    width: 32%;
    height: 70px !important;
    font-size: 18px !important;
    font-weight: 300 !important;
    letter-spacing: 1px;
    margin: 24px 0px;
  }

  .react-tel-input .form-control {
    padding-left: 94px !important;
    padding-top: 21px;
    width: auto !important;
  }

  .width-100-768 {
    width: 50%;
  }

  .width-second-768 {
    width: 50%;
    margin-left: 30px !important;
  }

  .disply-manu-768 {
    display: flex;
  }

  .price-payment-768 {
    height: 58px;
    border-radius: 29px;
  }

  .inpt-border-trans-pass {
    width: 15% !important;
  }

  .next-btn {
    color: black !important;
    font-size: 17px;
    font-weight: 700;
    text-align: end;
    display: block !important;
    /* padding: 0 25px; */
  }
  .padding-top-768 {
    padding-top: 9rem !important;
  }
  .next-btn-768 {
    display: none !important;
  }
  .time-price-768 {
    width: 100% !important;
  }
  .next-btn-true {
    display: none !important;
    /* padding: 0 25px; */
  }

  .next-btn-false {
    display: block !important;
    /* padding: 0 25px; */
  }

  .next-btn-event-true {
    display: block !important;
    /* padding: 0 25px; */
  }

  .next-btn-event-false {
    display: none !important;
    /* padding: 0 25px; */
  }

  .next-btn-event-clnder-true {
    display: none !important;
    /* padding: 0 25px; */
  }

  .next-btn-event-clnder-false {
    display: block !important;
    /* padding: 0 25px; */
  }

  .padding-left-17 {
    padding: 11rem 0 0 0 !important;
  }

  .true-event-type-clnder {
    display: none !important;
    /* padding: 0 25px; */
  }
  .false-event-type-clnder {
    display: block !important;
    /* padding: 0 25px; */
  }

  .true-first-event-type-clnder {
    display: block !important;
    /* padding: 0 25px; */
  }
  .false-first-event-type-clnder {
    display: none !important;
    /* padding: 0 25px; */
  }
}

@media screen and (max-width: 425px) {
  .card-bg {
    left: 18% !important;
    top: 33% !important;
  }
 

  .all_btn {
    display: block;
    margin-top: 41px;
  }

  .header-img {
    width: 50%;
  }

  .notification-text {
    margin: 10px 8px !important;
  }

  .notification-img {
    padding: 14px !important;
    margin: 8px 0px !important;
  }

  .submit_btn {
    right: 10% !important;
  }

  .back_btn {
    margin-bottom: 3px;
  }

  .btn-add-user {
    min-width: 80% !important;
  }

  .role-table {
    padding-left: 10px;
    padding-right: 10px;
  }

  .large-btn-768 {
    height: 72px !important;
    font-size: 16px !important;
    font-weight: 300 !important;
    letter-spacing: 0px;
    margin: 9px 0;
  }
}

@media screen and (max-width: 576px) {
  .width-100-768 {
    width: auto !important;
  }

  .width-second-768 {
    width: auto !important;
    margin-left: -10px !important;
  }

  .disply-manu-768 {
    display: block !important;
  }

  .form-margin-768 {
    width: 90% !important;
    margin: auto !important;
  }

  .inpt-768 {
    width: 100% !important;
    height: 50px !important;
    border-radius: 33px !important;
  }

  .price-upload-768 {
    width: 100% !important;
    height: 50px !important;
    border-radius: 29px;
  }

  .h2-768 {
    font-size: 21px !important;
  }

  .btn-add-user {
    height: 50px !important;
    border-radius: 29px !important;
    font-size: 17px !important;
  }

  .btn-768 {
    display: block !important;
    width: 100% !important;
    height: 50px !important;
    border-radius: 29px !important;
    font-size: 16px !important;
    margin-bottom: 20px !important;
  }

  .price-768 {
    width: 100% !important;
    height: 50px !important;
    border-radius: 29px;
  }

  .label {
    font-size: 15px !important;
  }
}

.next-btn {
  display: none;
}

@media screen and (max-width: 425px) {
  .form-margin-768 {
    width: 100% !important;
    margin: auto !important;
  }
}

/* .rbc-event-continues-prior {
  position: relative !important;
  top: -50px !important;
  width: 100% !important;
} */

.rbc-event-continues-prior div {
  display: none !important;
}

/* table */

.dataTables_wrapper .dataTables_length select {
  border: 1px solid #050303 !important;
  border-radius: 23px !important;
  padding: 5px;
  background-color: #d4d4d4 !important;
  color: inherit;
  padding: 4px;
}
.dataTables_wrapper .dataTables_length select:focus-visible{
  outline: none !important;
}
.dataTables_wrapper .dataTables_filter input {
  border: 1px solid #050303 !important;
  border-radius: 45px !important;
  padding-right: 31px !important;
  width: 270px;
  background-color: transparent !important;
  color: inherit;
  background-image: url(./images/searchimg\ .png);
  background-position: center right 3%;
  background-repeat: no-repeat;
  background-size: 19px;
  outline: none;
}

.dataTables_wrapper .dataTables_filter {
  float: none !important;
  text-align: center !important;
}

.role-table {
  margin: 0px 50px !important;
}

table.dataTable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  line-height: 2;
  border-collapse: collapse !important;
  border-spacing: 0;
  margin-top: 20px !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0px !important;
}

.add-btn {
  margin-right: 2.5rem !important;
}

@media screen and (max-width: 1440px) {
  .role-table {
    margin: 0px 18px 0px 0px !important;
  }

  .add-btn {
    margin-right: 1.5rem !important;
  }
}

@media (max-width: 768px) {
  .dataTables_info {
    display: none;
  }

  .add-btn {
    margin-right: 0px !important;
  }

  .role-table {
    margin: 0px 36px !important;
  }

  .p-float {
    float: left !important;
  }

  .add-role-btn-768 {
    display: block !important;
    width: 56% !important;
    text-align: center !important;
    margin: auto !important;
  }
  table.dataTable {
    margin-top: 60px !important;
  }
}

@media (max-width: 576px) {
  .add-role-btn-768 {
    display: block !important;
    width: 84% !important;
    text-align: center !important;
    margin: auto !important;
  }
}
@media (max-width: 425px) {
  .role-table {
    margin: 0px 0px 25px !important;
  }
}

@media screen and (max-width: 768px) {
  .dataTables_filter label input {
    float: left !important;
    width: 180% !important;
  }

  .dataTables_filter label input {
    width: 100% !important;
    margin-right: 214px;
  }

  .dataTables_filter input[type="search"] {
    padding: 12px 40px 12px 10px !important;
  }
}

.dataTables_wrapper .dataTables_length {
  float: right !important;
}

@media (max-width: 425px) {
  .add-role-btn-768 {
    display: block !important;
    width: 94% !important;
    text-align: center !important;
    margin: auto !important;
  }
}

.btn-calender:hover {
  color: #666666 !important;
}

.select-box {
  position: absolute !important;
  background-color: #4d4d4d !important;
  width: 280px !important;
  z-index: 1;
  max-height: 250px !important;
  overflow: auto;
  list-style: none;
  padding: 0;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
  overflow-y: auto;
  border-radius: 0 0 3px 3px;
}
.select-box1 {
  position: absolute !important;
  background-color: #4d4d4d !important;
  width: 195px !important;
  z-index: 1;
  max-height: 250px !important;
  overflow: auto;
  list-style: none;
  padding: 0;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
  overflow-y: auto;
  border-radius: 0 0 3px 3px;
}

.select-box-option {
  cursor: pointer;
  padding: 6px 13px;
  /* border-bottom: 1px solid #c2c0c0; */
}
.select-box-option:hover {
  background-color: #676767 !important;
  cursor: pointer;
  padding: 6px 13px;
  /* border-bottom: 1px solid #c2c0c0; */
}
.select-box-option-nodata {
  cursor: pointer;
  padding: 6px 13px;
  /* border-bottom: 1px solid #c2c0c0; */
}

.css-1nmdiq5-menu div {
  background-color: #4d4d4d !important;
  color: white !important;
  /* / width: 80% !important; / */
}
.css-1nmdiq5-menu {
  background-color: #4d4d4d !important;
  width: 85% !important;
}
.css-1nmdiq5-menu div:hover {
  background-color: #676767 !important;
}

.calender-select {
  height: 43px !important;
  border-radius: 8px !important;
}
.chart {
  width: 55% !important;
  height: 90% !important;
  /* margin: ; */
  margin: 52px 0 5px 42px;
}
@media (max-width: 1441px) {
  .chart {
    width: 65% !important;
  }
}
@media (max-width: 1024px) {
  .chart {
    width: 100% !important;
  }
  .next_btn {
    position: relative;
    text-align: end !important;
    right: 0 !important;
}
}
@media (max-width: 991px) {
  .chart {
    width: 70% !important;
  }
}
@media (max-width: 1113px) {
  .notification-img {
    padding: 11px;
    margin: 5px 0px !important;
  }
}
@media (max-width: 768px) {
  .calender-select {
    height: 51px !important;
    border-radius: 8px !important;
  }
  .chart {
    width: 85% !important;
  
  }
}

@media (max-width: 578px) {
  .notification-img {
    padding: 6px !important;
    margin: 20px 0px !important;
  }
  .card-file {
    top: 27% !important;
    right: 7% !important;
  }
  .chart {
    width: 100% !important;
    margin: 52px 0 5px 0px;
  }
}
@media (max-width: 572px) {
  .notification-img {
    padding: 6px !important;
    margin: 32px 0px !important;
  }
}
@media (max-width: 425px) {
  .notification-img {
    padding: 8px !important;
    margin: 38px 0px !important;
  }
}
@media (max-width: 320px) {
  .notification-img {
    padding: 8px !important;
    margin: 62px 0px !important;
  }
}

.card-file {
  background: #e5e5e5;
  border: 2px solid black;
  border-radius: 9px;
  top: 20%;
  right: 15%;
  z-index: 1;
}

@media screen and (max-width: 375px) {
  .card-file {
    top: 27% !important;
    right: 0% !important;
  }
  .form-margin-setting-768 {
    width: 100% !important;
    margin: auto !important;
  }
}
@media screen and (max-width: 320px) {
  .card-file {
    top: 27% !important;
    right: 0% !important;
  }
}

.notification-count {
  right: 3%;
  top: -6%;
  background: black;
  padding: 0px 6px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border-radius: 50%;
  position: absolute;
}
.notification-count1 {
  right: 3%;
  top: 9%;
  background: black;
  padding: 0px 6px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border-radius: 50%;
  position: absolute;
}

@media (max-width: 578px) {
  .notification-count1 {
    right: 4%;
    top: 9%;
  }
}
@media (max-width: 425px) {
  .notification-count1 {
    right: 5%;
    top: 9%;
  }
}
@media (max-width: 375px) {
  .notification-count1 {
    right: 6%;
    top: 9%;
  }
}
@media (max-width: 320px) {
  .notification-count1 {
    right: 7%;
    top: 9%;
  }
}

.btn-calender2 {
  margin-top: 0 !important;
  font-size: 15px !important;
  padding: 0px !important;
  font-weight: 500 !important;
}

@media screen and (max-width: 376px) {
  .large-btn-768-1 {
    display: block !important;
    margin: 7px 0;
    width: 100% !important;
  }
}

.more-padding{
  padding: 7px 168px !important;
}
.select-ios-div{
  padding: 12px !important;
    padding-left: 15px !important;
}
.select-ios{
 display: contents;
}

.rbc-abs-full, .rbc-row-bg {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: -1px !important;
}

.table-hover > tbody > tr:hover {
  background-color: lighten(cyan, 40%);
}
.table {
@media only screen and (min-width: 768px) {
  table-layout: fixed;
  max-width: 100% !important;
}
}
thead {
background: #ddd;
}

@media only screen and (max-width: 767px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
    font-size: 14px;
  }
  thead tr,
  tfoot tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .line-height{
    line-height: 1.3;
  }
  td {
    border: none;
    border-bottom: 1px solid #cfcfcf !important;
    position: relative;
    padding-left: 50% !important;
  }
  td:before {
    position: absolute;
    top: 8px;
    left: 9px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    font-weight: bold;
  }  

}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}



/* -------------pdf css ---------------*/

/* .wrapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  background-color: white !important;
}

.receipt-box {
  width: 768px;
  display: flex;
  flex-direction: column;
  border: 2px solid #e4e4e4;
}

.pdf-th,.pdf-td{
  padding: 2px !important;

}

.pdf-p{

} */
 
@media (min-width: 769px) {
  .hide-above-768 {
    display: none !important;
  }
}